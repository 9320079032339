//---------------------------------------------project_menu
.project_menu {
	
	@include bp($point_4 - 1) {
		position: absolute;
		top: calc(26vw + 10.6rem);
		left: 0;
		right: 0;
	
		height: calc(100% - 26vw - 10.6rem);
	
		overflow: hidden;
		overflow-y: auto;
	
		// transform: translateX(50%);
		opacity: 0;
		pointer-events: none;

		@include d3();

		&.active_mod {
			pointer-events: auto;
		}

	}

	@media screen and ( max-height: 440px ) {
		height: 100%;
	}
	
}

.project_menu_in {
	
	@include bp($point_4 - 1) {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;

		width: 100%;
		height: 100%;
	}
	
}

.project_menu_container {
	
	@include bp($point_4 - 1) {
		position: absolute;
		top: 0;
		left: 0;
	
		width: 100%;
		height: 100%;
		overflow-y: auto;
	}
	
}

.project_menu_container_in {
	// position: absolute;
	// top: 0;
	// left: 0;
	
	@include bp($point_4 - 1) {
		width: 100%;
		height: 77rem;
	}
	
}

.project_menu_block {
	
	@include bp($point_4 - 1) {
		position: absolute;
		top: 0;
		left: 0;
		// padding-top: 1.4rem;

		padding-left: $gl_mob_indent;
		padding-right: $gl_mob_indent;
		width: 100%;
		// height: 100%;

		will-change: transform;

		&.v1_mod {
			transform: translateY(-100%);
			// top: 100%;
			// top: 1.4rem;
			// height: 100%;
			// margin-top: 1.4rem;
			// transform: translateY(100%);
		}

		&.v2_mod {
			transform: translateY(100%);
			// top: 100%;
			// top: 1.4rem;
			// height: 100%;
			// margin-top: 1.4rem;
			// transform: translateY(100%);
		}
	}

}

// .project_menu_list {
// 	.v2_mod & {
// 		padding-top: 1.4rem;
// 	}
// }

.project_menu_list_item {
	
	@include bp($point_4 - 1) {
		margin-bottom: 1.4rem;

		overflow: hidden;

		&:last-of-type {
			margin-bottom: 0;
		}

	}
	
}

.project_menu_list_link {
	
	@include bp($point_4 - 1) {
		font-size: 3.2rem;
		font-weight: 500;
		color: var(--accent-color);
	}
	
}
//---------------------------------------------project_menu###