.category_list {
	@extend %global_flex_block_row_nowrap_flex-start;

	margin-bottom: 3.6rem;

	@include bp($point_4 - 1) {
		margin-right: -1rem;
	}
}

.category_item {

	overflow: hidden;

	@include bp($point_4, min) {
		margin-right: 5rem;

		&:last-child {
			margin-right: 0;
		}

	}
	
	@include bp($point_4 - 1) {
		padding-right: 3rem;

		&:last-child {
			padding-right: 0;
		}
	}
}

.category_link {
	@extend %transition_color;

	font-size: 2rem;
	color: var(--accent-color-opacity);

	cursor: pointer;

	@include d3();

	&.active_mod,
	&:hover,
	&:focus {
		color: var(--accent-color);
	}

	@include bp($point_4 - 1) {
		font-size: 1.4rem;
		letter-spacing: -.04rem;
	}

}

.project_links {
	@extend %global_flex_block_row_wrap_flex-start;
	
	@include bp($point_4, min) {
		margin-right: -1.6rem;
		margin-bottom: -1rem;
	}

	@include bp($point_4 - 1) {
		margin-right: -1rem;
		margin-bottom: -.2rem;
	}

}

.project_link_item {
	position: relative;
	
	@include bp($point_4, min) {
		padding-right: 1.6rem;
		padding-bottom: 1rem;
	}
	
	@include bp($point_4 - 1) {
		padding-right: 1rem;
		padding-bottom: .2rem;
	}

	&:hover {
		z-index: 10;

		//mix-blend-mode: normal;
		//mix-blend-mode: multiply;
		//mix-blend-mode: screen;
		//mix-blend-mode: overlay;
		//mix-blend-mode: darken;
		//mix-blend-mode: lighten;
		//mix-blend-mode: color-dodge;
		//mix-blend-mode: color-burn;
		//mix-blend-mode: hard-light;
		//mix-blend-mode: soft-light;
		//mix-blend-mode: difference;
		//mix-blend-mode: exclusion;
		//mix-blend-mode: hue;
		//mix-blend-mode: saturation;
		//mix-blend-mode: color;
		//mix-blend-mode: luminosity;
	}

}

.project_link {
	@extend %global_flex_block_row_nowrap_flex-start_center;
	@extend %transition_color;

	font-size: 5rem;
	line-height: 1;
	color: var(--accent-color);

	cursor: pointer;

	&.disabled_mod {
		color: var(--accent-color-opacity);
		text-shadow: none !important;
	}

	&:hover,
	&:focus {
		color: var(--accent-color-opacity);
		text-decoration: none;
		// text-shadow: 0 0 1px var(--accent-color-opacity), 0 0 1px var(--accent-color-opacity), 0 0 1px var(--accent-color-opacity);
	}

	@include bp($point_4 - 1) {
		font-size: 3rem;
	}

}

.project_link_w_title {
	display: block;
	overflow: hidden;
}

.project_link_title {
	position: relative;

	.project_link_item:first-child & {
		
		@include bp($point_2, min) {
			&:before {
				display: none;
			}
		}
		
	}

	&:before {
		
		@include bp($point_2, min) {
			content: '/';
			margin-right: 2.4rem;
		}
		
	}

	&:after {
		
		@include bp($point_3) {
			content: '/';
		}
		
		@media (min-width: $point_4) and (max-width: $point_3) {
			margin-left: 2.4rem;
		}
		
		@include bp($point_4 - 1) {
			margin-left: .8rem;
			font-size: 3.4rem;
		}
	}

	.project_link_item:nth-last-child(2) & {
		
		@include bp($point_3) {
			&:after {
				display: none;
			}
		}
		
	}

	@include d3();

}

.project_preview_w,
.project_preview_w_in {
	position: absolute;
	top: 0;
	left: 0;

	// width: 50.4rem;
	// height: 70rem;
	width: 100%;
	height: 100%;

	pointer-events: none;
	@include d3();
}

.project_preview_w_in {

	overflow: hidden;

	@include bp($point_2 + 1, min) {
		transform: scale(.26, .64);
		// transform-origin: 50% 100%;
	}
	
	@include bp($point_3) {
		transform: scale(.8);
	}
	
}

.project_preview {
	@extend %global_flex_block_row_nowrap_center_center;

	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	@include bp($point_2 + 1, min) {
		transform: scale(2.46, 1);
	}

	// position: fixed;
	// top: 0;
	// left: 0;

	// width: 50.4rem;
	// height: 70rem;

	// opacity: 0;

	// transition: opacity .3s;
	// z-index: 10;

	// .project_link_item:hover &,
	// .project_link_item:focus & {
	// 	opacity: 1;
	// 	pointer-events: initial;
	// }

	// @include bp($point_3) {
	// 	display: none;
	// }

}

.project_preview_img {
	position: absolute;
	top: 0;
	left: 0;

	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.project_preview_title {
	position: relative;
}

// contact

.contact_list {

	@include bp($point_2, min) {
		margin-left: 23rem;
	}

	@include bp($point_4, min) {
		overflow: hidden;
	}

	@include bp($point_4 - 1) {
		margin-top: auto;
		margin-bottom: auto;
	}

}

.contact_link_w {
	@extend %global_flex_block_row_nowrap_flex-start;

	position: relative;
	flex: auto;

	@include d3();
}

// .contact_link_w_in {
// 	position: relative;
// 	visibility: hidden;

// 	@include d3();

// 	&.copy_mod {
// 		position: absolute;
// 		opacity: 1;
// 		top: 50%;
// 		left: -1rem;
// 		right: -1rem;
// 		pointer-events: none;
// 		visibility: visible;
// 		border-radius: 50%;
// 		overflow: hidden;
// 	}

// }

// .contact_link_w_in:hover,
// .contact_link_w_in:focus {
// 	a.contact_link {
// 		text-decoration: none !important;
// 		color: $red !important;
// 	}
// }

.contact_w_link {
	overflow: hidden;
}

.contact_link {
	display: inline-block;
	font-size: 7.2rem;
	text-transform: uppercase;
	// color: $black;
	color: var(--accent-color);
	transition: color .3s;

	// border-bottom: 4px solid transparent;

	@include bp($point_4 - 1) {
		font-size: 3.2rem;
	}

	// &.copy_mod {
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 100%;
	// 	top: 0;
	// 	left: 0;
	// 	padding: 0 1rem;

	// 	@include d3();

	// 	&:before {
	// 		content: attr(data-text);
	// 	}

	// }

	@include d3();

	&:hover,
	&:focus,
	&:active {
		// color: $red !important;
		color: lighten($black, 30%) !important;
		text-decoration: none;
		// border-color: $black;
		// color: lighten($black, 10%);
	}

	&.main_mod {
		visibility: visible;
		opacity: 0;
	}

}

// design 

.design_block {
	@extend %global_flex_block_row_nowrap_flex-start;

	@include bp($point_2, min) {
		padding-left: 23rem;
		padding-right: 11rem;
	}
	
	@include bp($point_4 - 1) {
		flex-wrap: wrap;
		margin-bottom: 2rem;
	}

	overflow: hidden;
}

.design_block_col {
	
	@include bp($point_4, min) {
		padding-right: 3rem;
		width: 50%;
	}
	
	@include bp($point_4 - 1) {
		width: 100%;
	}
	
	
}

.design_w_title {
	line-height: 1.2;
	text-transform: uppercase;

	overflow: hidden;

	@include bp($point_4, min) {
		font-size: 7.2rem;
	}

	@include bp($point_4 - 1) {
		font-size: 3.2rem;
	}

}

.design_title {
	.design_w_title:nth-child(2) & {
		position: relative;
    padding-left: 6rem;
		text-align: right;
		
		&:before,
		&:after {
			position: absolute;
			top: 50%;

			background-color: var(--accent-color);

			content: '';
		}

		&:before {
			
			@include bp($point_4, min) {
				left: 2.3rem;
				width: .6rem;
				height: 5.2rem;
				margin-top: -2.6rem;
			}
			
			@include bp($point_4 - 1) {
				left: 1.3rem;
				width: .3rem;
				height: 2.8rem;
				margin-top: -1.4rem;
			}

		}

		&:after {
			left: 0;
			
			@include bp($point_4, min) {
				height: .6rem;
				width: 5.2rem;
				margin-top: -.3rem;
			}
			
			@include bp($point_4 - 1) {
				height: .3rem;
				width: 2.9rem;
				margin-top: -.1rem;
			}

		}

	}
}

.design_block_w_title {
	
	@include bp($point_4, min) {
		max-width: 43rem;
	}

	// .contact_link_w:nth-child(2) {
		
	// 	@include bp($point_4 - 1) {
	// 		padding-right: 1rem;
	// 	}
		
	// 	> .contact_link_w_in {
	// 		width: 100%;
	// 		margin-left: auto;

	// 		.contact_link.copy_mod {
	// 			display: flex;

	// 			&:before {
	// 				margin-left: auto
	// 			}

	// 			&:after {
	// 				content: '+';
	// 				order: -1;
	// 			}

	// 		}

	// 	}
		

	// 	// &:after, &:before {
	// 	// 	content: '';
	// 	// 	position: absolute;
	// 	// 	top: 50%;

	// 	// 	visibility: visible;

	// 	// 	background-color: $black;
	// 	// }

	// 	// &:before {
	// 	// 	left: 2.3rem;

	// 	// 	width: .6rem;
	// 	// 	height: 5.2rem;

	// 	// 	margin-top: -2.6rem;

	// 	// 	@include bp($point_4 - 1) {
	// 	// 		left: 1.3rem;

	// 	// 		width: .4rem;
	// 	// 		height: 3rem;
	// 	// 		margin-top: -1.5rem;
	// 	// 	}
	// 	// }

	// 	// &:after {
	// 	// 	left: 0;

	// 	// 	height: .6rem;
	// 	// 	width: 5.2rem;
	// 	// 	margin-top: -.3rem;

	// 	// 	@include bp($point_4 - 1) {
	// 	// 		height: .4rem;
	// 	// 		width: 3rem;
	// 	// 		margin-top: -.2rem;
	// 	// 	}
	// 	// }
	// }

	@include bp($point_4 - 1) {
		max-width: 22rem;
		margin-bottom: 3.2rem;

		line-height: 1.2;

		// font-size: 3.8rem;
	}

}

.design_title_w {
	overflow: hidden;
}

.design_text {
	// letter-spacing: .18px;
	letter-spacing: -.02rem;

	@include bp($point_2, min) {
		font-size: 2.5rem;
	}

	@include bp($point_3) {
		font-size: 1.8rem;
	}

	@include bp($point_4, min) {
		line-height: 1.5;
	}
	
	@include bp($point_4 - 1) {
		line-height: 1.4;
	}

}

.design_text_wrap {
	overflow: hidden;

	margin-bottom: 2rem;

	&:last-child {
		margin-bottom: 0;
	}

}

.design_text_wrap_in {
	@include d3();
}

// project

.project_img_wrap {
	height: 100vh;
}

.project_img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;

	.project_main_w_image & {
		
		@include bp($point_3) {
			position: absolute;
			top: 0;
			left: 0;

			@include d3();
		}
		
	}

}

.project_block_img_wrap_in,
.project_main_w_image_in {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	@include d3();

	svg {
		display: block;
		width: 100%;
		height: 100%;
		
		object-fit: cover;
	}

	line,
	polyline,
	path {
		stroke: var(--accent-color);
	}

}

// .project_main_w_image_in {
	
// 	@include bp($point_3) {
// 		position: relative;
// 	}
	
// }

.project_block {
	@extend %global_flex_block_row_wrap_flex-start_center;

	@include bp($point_2, min) {
		margin-right: -2rem;
	}
	
}

.project_col {
	
	@include bp($point_2, min) {
		width: 50%;
		padding-right: 2rem;
	}

	@include bp($point_3) {
		width: 100%;
		&.descr_mod {
			margin-bottom: 8.4rem;
		}
	}

}

.project_descr {
	max-width: 44.8rem;

	@include bp($point_3) {
		max-width: 100%;
	}
}

.project_w_title,
.project_w_text {
	overflow: hidden;
}

.project_w_title {

	@include bp($point_4, min) {
		margin-bottom: 5rem;
	}
	
	@include bp($point_4 - 1) {
		margin-bottom: 3rem;
	}

}

.project_title_w {
	@extend %global_flex_block_row_nowrap_flex-start;

	position: relative;
	flex: auto;
}

.project_title {
	// font-size: 9.2rem;
	font-size: 9rem;
	line-height: 1;
	//text-transform: uppercase;

	overflow: hidden;

	@include bp($point_3) {
		font-size: 6rem;
	}

}

.project_title_w_in {
	@include d3();
}

.project_text {
	font-size: 1.8rem;
	letter-spacing: .18px;

	@include bp($point_4, min) {
		line-height: 1.8;
	}
	
	@include bp($point_4 - 1) {
		line-height: 1.7;
	}

	@include d3();

}

.project_w_block {
	&.column_mod {
		
		@include bp($point_2, min) {
			max-width: 114.4rem;
			margin-left: auto;
			margin-right: auto;
			width: 100%;
		}
		
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	@include bp($point_2 + 1, min) {
		margin-bottom: 14rem;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		margin-bottom: 8.2rem;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 6.2rem;
	}

	@include d3();
}

.project_block_row {
	@include bp($point_2, min) {
		@include flex_block(row, wrap, flex-start, center, center);

		margin-right: -1rem;
	}
}

.project_main_w_image {
	position: relative;

	overflow: hidden;

	@include bp($point_2, min) {
		height: 100vh;
	}

	@include bp($point_3) {
		padding-bottom: 71.25%;
		opacity: 0;

		@include d3();
	}
	
}

.project_block_img_wrap {
	position: relative;
	max-width: 100%;
	width: 100%;

	overflow: hidden;

	@include d3();

	&:after {
		content: '';
		display: block;
		padding-bottom: 139%;
	}

	&.v1_mod {
		width: 100%;

		&:after {
			padding-bottom: 139%;
		}

		@include bp($point_3) {
			// position: absolute;
			// right: 0;

			width: 67%;
			margin-left: auto;
		}
	}

	&.v2_mod {
		// position: absolute;
		// left: 0;
		width: 71.4%;

		&:after {
			padding-bottom: 50.4%;
		}

		@include bp($point_3) {
			width: 90%;
			margin-left: -$gl_mob_indent;
		}

	}

	// &.v3_mod {
	// 	// position: absolute;
	// 	margin: 0 auto;
	// 	width: 68rem;
	// 	max-width: 100%;

	// 	&:after {
	// 		padding-bottom: 126%;
	// 	}

	// 	@include bp($point_3) {
	// 		width: 50%;
	// 	}

	// }

	&.left_align_mod {

		@include bp($point_2, min) {
			max-width: 102.4rem;
			height: 52rem;
		}

		@media (min-width: $point_4) and (max-width: $point_3) {
			margin-left: -$gl_indent;
		}

		@include bp($point_4 - 1) {
			margin-left: -$gl_mob_indent;
		}

	}

	&.center_align_mod,
	&.center_align_v1_mod,
	&.left_align_mod,
	&.column_mod {
		
		@include bp($point_3) {
			position: relative;

			padding-bottom: 56.25%;
			height: 0;
			overflow: hidden;
		}
		
	}

	&.column_mod {
		@include bp($point_3) {
			padding-bottom: 0;
		}

		@media (min-width: $point_4) and (max-width: $point_3) {
			height: 83rem;
		}

		@include bp($point_4 - 1) {
			height: calc(100vw * 1.4);
		}

		// @media (min-width: $point_7) and (max-width: $point_4 - 1) {
		// 	height: 64vh;
		// }

		// @include bp($point_7 - 1) {
		// 	height: 74vh;
		// }

	}

	&.center_align_mod {
		@include bp($point_2, min) {
			max-width: 114.4rem;
			height: 70rem;
			margin: 0 auto;
		}

	}

	&.center_align_v1_mod {
		
		@include bp($point_2, min) {
			max-width: 82rem;
			height: 54.2rem;
			margin: 0 auto;
		}
		
	}

	&.column_mod {
		
		@include bp($point_2, min) {
			height: 83rem;
		}
		
	}

}

.project_w_block_col {
	
	@include bp($point_2, min) {
		width: 50%;
		padding-right: 1rem;
	}

	@include bp($point_3) {
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	
	@media (min-width: $point_4) and (max-width: $point_3) {
		margin-bottom: 8.2rem;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 6.2rem;
	}
	
}

.project_block_img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

// CURSOR
.cursor {
	z-index: 1000;

	position: fixed;

	width: 12.4rem;
	height: 12.4rem;
	margin-top: -6.2rem;
	margin-left: -6.2rem;

	font-size: 1rem;

	@include d3();

	// background-image: url('../i/сursor.svg');
	// background-repeat: no-repeat;
	// background-position: 50% 50%;
	// background-size: cover;
  
	// opacity: 0;
	pointer-events: none;

	// &.active_mod {
	// 	opacity: 1;
	// }

	// &.prev_mod {
	// 	background-image: url('../i/arrow-slider-prev.svg');
	// }

}

.cursor_img {
	display: block;
	width: 100%;
	height: 100%;
	
	object-fit: cover;
	fill: var(--accent-color);
	transition: fill .3s ease;
}

.btn_back {
	cursor: pointer;

	text-align: center;
}

.btn_back_title {
	margin-bottom: 3.4rem;

	font-size: 1.8rem;
}

.btn_back_arrow {
	width: .9rem;
  height: 9.4rem;
  margin: 0 auto;

  animation: downarrow 0.6s infinite alternate ease-in-out;
}

.btn_back_arrow_in {
	display: block;
	height: 100%;
	width: 100%;

	object-fit: cover;

	transition: fill .3s ease;
	fill: var(--accent-color);
}

@keyframes downarrow {
  0% {
  	transform: translateY(0);
  }
  100% {
  	transform: translateY(2rem);
  }
}