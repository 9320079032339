//------------------------------------------------------------base styles
:root {
	--full-screen: calc(var(--vh, 1vh) * 100);

	// white
	--body-color: #fff;
	--decor-color: #ff0000;
	--accent-color: #000;
	--accent-color-opacity: #c1c1c1;
}


@include bp($point_2 + 1, min) {
	/* width */
	::-webkit-scrollbar {
		width: 5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background-color: $white;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background-color: #888;
		border-radius: 1rem;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background-color: #555;
	}
}


html {
	height: 100%;
	font-weight: 400;
	line-height: 1.33;
	font-family: $font_1;

	@include bp($point_2, min) {
		font-size: var(--rem);
	}

	// // from 1921 and above
	// @include bp($point_9 + 1, min) {
	// 	font-size: calc(100vw / #{$point_scale} * 4.8);
	// }

	// // from 1920 to 1441
	// @media (min-width: $point_0 + 1) and (max-width: $point_9) {
	// 	font-size: calc(100vw / #{$point_scale} * 6.8);
	// }
	
	// // from 1440 to 1024
	// @media (min-width: $point_2) and (max-width: $point_0) {
	// 	font-size: calc(100vw / #{$point_scale} * 10);
	// }
	
	@include bp($point_3) {
		font-size: 10px;
	}
	
	@include bp($point_7 - 1) {
		font-size: 8px;
	}

	// @include bp($point_2 + 1, min) {
	// 	cursor: none;

	// 	* {
	// 		&:hover,
	// 		&:focus {
	// 			cursor: none;
	// 		}
	// 	}
	// }

}

body {
	position: relative;

	height: 100%;

	font-size: $font_size_base;
	// color: $color_text_base;
	color: var(--accent-color);

	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background: var(--body-color);
	// transition: background .3s ease;
	overflow: hidden;
	opacity: 0;
	transition: all .3s ease;

	&.loaded_mod {
		opacity: 1;
	}

}

.wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	// min-height: 100%;
	// height: auto !important;
	height: 100%;
	overflow: hidden;

	overflow-x: hidden;
	
	// @include bp($point_2 + 1, min) {
	// 	position: relative;
	// }
	
	// @include bp($point_2) {
	// 	position: fixed;
	// 	top: 0;
	// 	left: 0;
	// }

}

.base {
	width: 100%;
	position: relative;
	// padding: $height_header 0 0;

	height: 100%;
	// overflow-y: auto;
	overflow: hidden;

	// &.project_mod {
	// 	padding-top: 0;
	// }

	> div {
		height: 100%;
		width: 100%;
	}

}

a {
	text-decoration: none;
	color: $color_link_base;

	&:hover,
	&:focus {
		text-decoration: underline;
	}

}

button {
	font-family: inherit;
}

//------------------------------------------------------------base styles###
