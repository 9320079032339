//---------------------------------------------sections
.section_wrap {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
	overflow-y: scroll;

	@include bp($point_2, min) {
		z-index: 50;
	}

	@include d3();
}

.section_in_wrap {
	@include d3();
}

.section {
	// position: absolute;
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	padding: 11rem $gl_indent;

	font-size: 1rem;

	overflow: hidden;

	&.full_mod {
		@extend %global_flex_block_row_nowrap_flex-start_center;

		min-height: calc(100vh - #{$height_footer} - #{$height_header});

		@include bp($point_4 - 1) {
			min-height: calc(100vh - #{$height_mob_footer});
		}

	}

	&.no_offset_mod {
		padding: 0;
	}

	&.hero_mod {

		@include bp($point_2, min) {
			height: 100%;
		}

		@include bp($point_4, min) {
			@include flex_block(row, nowrap, flex-start, center, center);
		}

		@include bp($point_3) {
			padding-bottom: 3.8rem;

			overflow: hidden;
			overflow-y: auto;
		}

		@media (min-width: $point_4) and (max-width: $point_3) {
			height: calc(100% - #{$height_footer});
		}

		@include bp($point_4 - 1) {
			height: calc(100% - #{$height_mob_footer});
		}

	}

	&.home_mod {

		@include bp($point_4 - 1) {
			// position: static;
		}

	}

	&.inner_mod {
		position: relative;
	}

	&.no_offset_top {

		@include bp($point_4, min) {
			padding-top: 0;
		}

		@include bp($point_4 - 1) {
			padding-top: 4.8rem;
		}

	}

	@include bp($point_4 - 1) {
		padding: 4.8rem $gl_mob_indent;
	}

	&.project_hero_mod  {
		
		@include bp($point_4 - 1) {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			align-content: center;
		}
		
	}

	@include bp($point_3) {
		z-index: 90;
		@include d3();
	}

	&.content_anim_mod {
		
		// @include bp($point_3) {
		// 	opacity: 0;
		// }
		
	}

}


.section_in {
	max-width: $page_width;
	width: 100%;
	margin: 0 auto;

	&.v2_mod {
		max-width: 114.4rem;
	}

	&.footer_mod {
		@extend %global_flex_block_row_nowrap_flex-start_center;
	}

	.home_mod & {
		
		@media (min-width: $point_4) and (max-width: $point_3) {
			height: 100%;
		}
		
	}

	&.hero_mod {

		@include bp($point_4 - 1) {
			display: flex;
			flex-direction: column;
			min-height: 100%;
		}

	}

}

.section_title {
	font-size: 4em;
}
