//------------------------------------------------------------layout
.header {
	@extend %global_flex_block_row_nowrap_flex-start_center;
	
	position: fixed;
	top: 0;
	left: 0;

	height: $height_header;
	padding: 2rem $gl_indent;

	width: 100%;

	font-size: 1rem;

	@include z-index(header);
	@include d3();

	@include bp($point_4 - 1) {
		padding: 0;
		height: 0;
	}

}

.logo {
	@extend %transition_color;
	
	display: block;
	width: 100%;

	color: var(--accent-color);

	cursor: pointer;

	// &.menu_mod {
	// 	// margin-bottom: 10.4rem;

	// 	@include bp($point_4, min) {
	// 		display: none;
	// 	}

	// }
	
	@include bp($point_0, min) {
		margin-bottom: 18.6rem;
	}
	
	@media (min-width: $point_2) and (max-width: $point_0 - 1) {
		margin-bottom: 9.6rem;
	}
	
	@include bp($point_2, min) {
		max-width: 185.6rem;
    height: 53.2rem;
    width: 100%;
	}

	@include bp($point_3) {
		margin-bottom: 10.6rem;
	}

	@include bp($point_4 - 1) {
		height: 26vw;
	}
	
	@include bp($point_7 - 1) {
		margin-bottom: 6rem;
	}

	&.v1_mod {
		margin-bottom: 0;
	}

}

.logo_img {
	display: block;
	width: 100%;

	fill: currentColor;

	@include bp($point_4 - 1) {
		height: 100%;
		object-fit: contain;
	}

}

.main_menu_list {
	
	@include bp($point_4, min) {
		margin-right: -5rem;

		@include flex_block(row, nowrap, flex-start, center, center);
	}

	@include bp($point_4 - 1) {
		margin-bottom: 4rem;

		&.disabled_mod {
			pointer-events: none;
		}

	}

}

.main_menu_item {
	@include bp($point_4, min) {
		padding-right: 5rem;
		
		&:last-child {
			margin-left: auto;
		}

	}

	@include bp($point_4 - 1) {
		margin-bottom: 1.6rem;
		overflow: hidden;

		&:last-child {
			margin-bottom: 0;
		}

	}

}

.main_menu_link {
	display: block;
	// color: $black;
	color: var(--accent-color);
	letter-spacing: 0.36px;
	text-transform: uppercase;

	border-bottom: 1px solid transparent;

	cursor: pointer;
	
	&:hover, &:focus {
		border-color: var(--accent-color);
		text-decoration: none;
	}
	
	@include bp($point_2, min) {
		font-size: 1.8rem;
		// font-size: 4rem;
	}
	
	@media (min-width: $point_4) and (max-width: $point_3) {
		font-size: 1.4rem;
	}

	@include bp($point_4 - 1) {
		font-size: 3.2rem;
		letter-spacing: .64px;

		@include d3();
	}

}

.menu_wrap {
	@include bp($point_4 - 1) {
		position: fixed;
		top: 0;
		left: 0;

		display: flex;
		flex-direction: column;
		width: 100%;
		height: calc(100% - #{$height_mob_footer});
		padding: 4.8rem 1.6rem 3.8rem;
		
		background: var(--body-color);

		overflow: hidden;
		overflow-y: auto;
		
		opacity: 0;
		pointer-events: none;
		transition: background .3s ease;

		z-index: 100;

		// body.menu_open & {
		// 	opacity: 1;
		// 	pointer-events: initial;
		// }

	}

}


//------------------------------------------------------------layout###