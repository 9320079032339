//---------------------------------------------fonts
$font_path: "../../fonts/";
$font_1: 'NeueHaasDisplay', sans-serif;
$font_size_base: 1.6rem;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$black: #000;

$red: #ff0000;

$magenta: #ff00b8;
$yellow: #fff500;
$blue: #1900ff;

$color_text_base: $black;
$color_link_base:#4aa3df;
$body_bg: $white;
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
	popap : 9999,
	header : 110,
	footer : 100
);
//---------------------------------------------z-index###

//---------------------------------------------layout
$height_mob_footer: 5.6rem;
$height_footer: 9.2rem;
$height_header: 8.6rem;
$page_width: 100%;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_indent: 3.2rem;
$gl_mob_indent: 1.6rem;
$gl_radius:.5rem;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_0: 1440px;
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
$point_9: 1920px;

$point_scale: 1440;
//---------------------------------------------media points

//---------------------------------------------forms
$input_height: 4rem;
$input_offset: 0 2rem;
$input_font_size: $font_size_base;
$input_border_color: #ccc;
$input_border_color_active: $black;
$input_placeholder_color: $black;
$input_text_color: $black;
$input_radius: $gl_radius;
//---------------------------------------------forms###