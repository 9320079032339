.footer {
	@extend %global_flex_block_row_nowrap_flex-start_center;

	position: absolute;
	bottom: 0;
	
	width: 100%;
	flex: 0 0 auto;
	padding: 3.2rem $gl_indent;

	font-size: 1rem;

	z-index: 100;
	@include d3();


	@include bp($point_4, min) {
		height: $height_footer;
	}

	@include bp($point_4 - 1) {
		height: $height_mob_footer;
		padding: 2rem $gl_mob_indent;
	}

}

.footer_overlay {

	@include bp($point_4 - 1) {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background: var(--body-color);

		transition: background .3s ease;
		opacity: 0;
	}
	
}

.footer_copyright {
	letter-spacing: .36px;

	@include bp($point_2, min) {
		font-size: 1.8rem;
	}

	@include bp($point_4, min) {
		margin-right: auto;
	}

	@include bp($point_3) {
		font-size: 1.4rem;
	}

	@include bp($point_4 - 1) {
		overflow: hidden;

		&.menu_mod {
			margin-top: auto;
			overflow: auto;
		}

		&.disabled_mod {
			pointer-events: none;
		}

	}

}

.footer_copyright_in {
	
	@include bp($point_4 - 1) {
		overflow: hidden;
		@include d3();
	}
	
}

.footer_copyright_in_title {
	
	@include bp($point_4 - 1) {
		@include d3();
	}
	
}

.footer_decor {
	@extend %transition_background;

	width: 1.6rem;
	height: 1.6rem;
	flex-shrink: 0;

	// background-color: var(--decor-color);
	border-radius: 50%;
	cursor: pointer;
	overflow: hidden;
	@include d3();
	// animation: pulsate 1s ease-out;
	// animation-iteration-count: infinite; 
	// opacity: 0;
}

.footer_decor_in {
	display: block;
	width: 100%;
	height: 100%;
	
	fill: var(--decor-color);

	path {
	}
}

// @keyframes pulsate {
// 	0% {
// 		transform: scale(0);
// 		opacity: 0;
// 	}
// 	50% {
// 		opacity: 1;
// 	}
// 	100% {
// 		transform: scale(1);
// 		opacity: 0;
// 	}
// }

.footer_w_adress {
	margin-top: auto;
}

.footer_adress {
	letter-spacing: 0.36px;

	
	@include bp($point_2, min) {
		font-size: 1.8rem;
	}
	
	@include bp($point_4, min) {
		margin-right: 5rem;
	}
	
	@include bp($point_3) {
		font-size: 1.4rem;
	}
	
	@include bp($point_4 - 1) {
		margin-bottom: 1.2rem;

		overflow: hidden;
	}
	
}

.footer_adress_in {
	
	@include bp($point_4 - 1) {
		@include d3();
	}
	
}


//---------------------------------------------trigger
@mixin user_menu_butt_line {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: 1px;
	margin-top: -.1rem;
	background: var(--accent-color);
}

.menu_trigger {
	
	@include bp($point_4, min) {
		display: none;
	}
	
	@include bp($point_4 - 1) {
		position: relative;
		z-index: 210;

		display: block;
		height: 1rem;
		width: 4.4rem;
		margin-right: auto;
		text-transform: uppercase;

		color: transparent;
		cursor: pointer;
	}

	&:before {
		content: '';
		transform: translate(0, -.2rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&:after {
		content: '';
		transform: translate(0, .5rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&.active_mod {
		&:before {
			transform: (rotate(10deg) translate(0, 0));
		}

		&:after {
			transform: (rotate(-10deg) translate(0, 0));
		}
	}
}

//---------------------------------------------trigger###